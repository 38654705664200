import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Pusher from 'pusher-js';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-orders-display',
  templateUrl: './orders-display.component.html',
  styleUrls: ['./orders-display.component.css']
})
export class OrdersDisplayComponent implements OnInit {

  locationDetailsId: any = `${localStorage.getItem('location_details_id')}`;
  pusher = new Pusher('8d0ad959b5759a0aa3ca', {
    cluster: 'ap2'
  });
  OrderList = [];
  loading = false;
  singleImage: any;
  dispatchOrderId;
  orderId;
  dispatchOrders:any=[];

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    var channel = this.pusher.subscribe('blaack-forest');
    channel.bind(`shop-${this.locationDetailsId}`, (data) => {
       if(this.router.url == '/orderList'){
        this.listAllShopOrders();
      }
  });
    this.listAllShopOrders();
  }

  listAllShopOrders(){
    this.loading = true;
    this.apiService.getData(`getShopOrderById/${this.locationDetailsId}`).subscribe((data) => {
      this.dispatchOrders = data.data;
      console.table(this.dispatchOrders)
       this.OrderList = data.data[0].order.ordered_products;
       this.dispatchOrderId = data.data[0].dispatch_order_id;
       this.orderId = data.data[0].order_id;

        this.loading = false;
    }, error => {
        this.loading = false;
    });
  }

}
