import { Branch } from './../../model/branch';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loading: boolean;
    userName: any;
    password: any;
    usertype: any = 'cashier';
    pincodeList: any = [];
    locationDetailsId = 0;
    shopList: any;
    constructor(private route: Router, private apiService: ApiService, private toastr: ToastrService) { }

    ngOnInit() {
        localStorage.clear();
        this.listAllShop();
    }

    userType(type) {
        this.usertype = type;
    }

    listAllShop(){
        this.loading = true;
        this.apiService.getData('getAllActiveShopDetails').subscribe((data) => {
            this.shopList = data.data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
       }

       onChange(event){
        this.locationDetailsId = event;
       
       }

    async login() {
        this.loading = true;
        if (this.userName && this.password && this.locationDetailsId) {
            const value = { shop_name: this.userName, password: this.password, shop_details_id: this.locationDetailsId };
            await this.apiService.postData(value, 'loginShop').subscribe(async data => {
                // return false;
                if (data.error === false) {
                    this.toastr.success(data.message);
                    localStorage.setItem('dispatch_name', data.data.shop_name);
                    localStorage.setItem('user_token', data.data.token);
                    this.loading = false;
                    localStorage.setItem('location_details_id', data.data.shop_details_id);
                    localStorage.setItem('location_name', data.data.location_name);

                    // if (data.data.role_id == 1) {
                    //     localStorage.setItem('id', data.data.id);
                    //     localStorage.setItem('role', 'user');
                    //     localStorage.setItem('role_name', 'Super Admin');
                    // } else if (data.data.role_id == 2) {
                    //     localStorage.setItem('id', data.data.id);
                    //     localStorage.setItem('role', 'user');
                    //     localStorage.setItem('role_name', 'Admin');
                    // } else if (data.data.role_id == 3) {
                    //     localStorage.setItem('id', data.data.cashier_id);
                    //     localStorage.setItem('role', 'cashier');
                    //     localStorage.setItem('role_name', 'Cashier');
                    //     localStorage.setItem('branchId', data.data.branch_id);
                    //     await this.apiService.getData('getCashierBranch', data.data.cashier_id).subscribe((val) => {
                    //         localStorage.setItem('branchName', val.data.branch[0].branch_name);
                    //     });
                    // }

                    await this.apiService.getData('getAllLocationDetailsById', data.data.location_details_id).subscribe((val) => {
                        console.log(val);
                                localStorage.setItem('location_name', val.data.location_name);
                            });

                    
                    this.route.navigate(['orderList']);
                } else {
                    this.toastr.error(data.message);
                    this.loading = false;
                }
            }, (error) => {
                this.toastr.error('Something went wrong try after some time.');
                this.loading = false;
            });
        } else {
            this.toastr.error('Fill the Madatory Params to Proceed');
            setTimeout(() => {
                this.loading = false;
            }, 200);
        }
    }
}
