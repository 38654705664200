import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

let headers = new HttpHeaders();
@Injectable({
    providedIn: 'root'
})
export class ApiService {

   // apiURL = "http://localhost:8002/api";
   // apiImageUrl =  'http://localhost:8002/images';
	
	apiURL = 'https://blaackforestcakes.com/bfserver/api'; // Live
	apiImageUrl = 'https://blaackforestcakes.com/bfserver/images'; // Live


   constructor(private http: HttpClient) { }

    public getData(url, params: any = '') {
        headers = headers.set('Accept', 'application/json').set('Authorization', `Bearer ${localStorage.getItem('user_token')}`);
        if (params) {
            return this.http.get<any>(`${this.apiURL}/` + url + `/` + params, { headers });
        }
        return this.http.get<any>(`${this.apiURL}/` + url, { headers });
    }

    public postData(data, url) {
        headers = headers.set('Accept', 'application/json').set('Authorization', `Bearer ${localStorage.getItem('user_token')}`);
        return this.http.post<any>(`${this.apiURL}/` + url, data, { headers });
    }

    public searchData(url, params: any = '') {
        const check = params.split('/');
        if (!check[check.length - 1].length) {
            params = `${params}null`;
        }
        headers = headers.set('Accept', 'application/json').set('Authorization', `Bearer ${localStorage.getItem('user_token')}`);
        return this.http.get<any>(`${this.apiURL}/` + url + `/` + params, { headers });
    }

    public getImageUrl() {
        return this.apiImageUrl;
    }
}
